<template>
  <div>
    <en-table-layout :tableData="pageData" :redundancy-height="0">
      <template slot="table-columns">
        <el-table-column label="商品图片" prop="thumbnail" width="80" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px">
              <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.goods_name }}
          </template>
        </el-table-column>
        <el-table-column label="商品编号" width="160">
          <template slot-scope="scope">
            {{ scope.row.sn }}
          </template>
        </el-table-column>
        <el-table-column label="销售价" width="90" prop="revisePrice">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_type === 'POINT'">
              {{ scope.row.revise_exchange_money | unitPrice('￥') }} +
              {{ scope.row.revise_exchange_point || 0 }}
              积分
            </span>
            <span v-else>
              {{ scope.row.revise_price | unitPrice('￥') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="$event => clickRemove(scope)">移除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </en-table-layout>
  </div>
</template>

<script>
import EnTableLayout from '../../../../../../../ui-components/TableLayout/src/main'
export default {
  name: 'ManagerSellerCouponTable',
  components: {
    EnTableLayout
  },
  props: {
    pageData: {
      type: Array,
      default: _ => []
    }
  },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {
    // 移除商品
    clickRemove (row) {
      this.$emit('clickRemove', row)
    }
  },
};
</script>

<style lang="scss" scoped>
  /deep/ .el-table--border{
    min-height: 458px;
  }
</style>