<template>
  <div class="phone">
    <div class="phone-top">

    </div>

    <div class="couponBox">
      <div class="couponBox-up">
        <div class="couponBox-up-class">{{ couponBody.coupon_name }}</div>
        <div class="couponBox-up-value" v-if="couponBody.coupon_type == 1">{{ couponBody.coupon_value }}折</div>
        <div class="couponBox-up-full" v-else>
          <div>￥{{ couponBody.coupon_value }}</div>
          <div class="couponBox-up-full-full">满{{ couponBody.coupon_threshold_price }}元可用</div>
        </div>
      </div>
      <div class="couponBox-down">
        <div class="couponBox-down-scoped">适用范围：&nbsp;{{ couponBody.goods_limit ? '部分商品使用' : '全场通用' }}</div>
        <div class="couponBox-down-time">有效期：&nbsp;{{ `${couponBody.valid_timelong}
                  ${valid_timelong_unit_filter(couponBody.valid_timelong_unit)}` }}</div>
      </div>
      <div class="couponBox-circlLeft"></div>
      <div class="couponBox-circlRight"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ManagerSellerCouponPhoneModel',
  props: {
    couponBody: {
      type: Object,
      require: true
    }
  },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {
    valid_timelong_unit_filter (value) {
      console.log(value)
      if (value == 1) {
        return '个月'
      } else if (value == 2) {
        return '年'
      } else {
        return '天'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.phone {
  width: 375px;
  height: 667px;
  background-color: #f5f5f5;

  &-top {
    width: 100%;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../../../../../assets/phone-top.jpg);
  }

  .couponBox {
    margin: 0 auto;
    position: relative;
    margin-top: 50px;
    width: 320px;
    height: 200px;
    background-color: #fff;
    border-radius: 4px;



    &-up {
      width: 100%;
      height: 100px;
      padding-left: 26px;
      border-bottom: 1px dashed #ccc;

      &-full {
        font-size: 26px;
        font-weight: 900;
        color: #666;

        &-full {
          font-weight: 400;
          font-size: 12px;
        }
      }

      &-class {
        padding-top: 6px;
        height: 30px;
        font-size: 16px;
        font-weight: 700;
        color: #999;
        line-height: 30px;
      }

      &-value {
        font-size: 30px;
        font-weight: 900;
        color: #666;
      }
    }

    &-down {
      width: 100%;
      height: 100px;
      padding-left: 26px;
      padding-top: 16px;
      color: #999;
      font-size: 12px;
      line-height: 26px;

      &-scoped {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          background-color: #999;
          border-radius: 50%;
          top: 9px;
          left: -10px;
          transform: translate(50%, 50%);
        }
      }

      &-time {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          background-color: #999;
          border-radius: 50%;
          top: 9px;
          left: -10px;
          transform: translate(50%, 50%);
        }
      }
    }

    &-circlLeft {
      position: absolute;
      background-color: #f5f5f5;
      height: 20px;
      width: 20px;
      top: 50%;
      left: -10px;
      transform: translate(0%, -50%);
      border-radius: 50%;

    }

    &-circlRight {
      position: absolute;
      background-color: #f5f5f5;
      height: 20px;
      width: 20px;
      top: 50%;
      right: -10px;
      transform: translate(0%, -50%);
      border-radius: 50%;
    }
  }
}
</style>