<template>
  <div>
    <!-- 代金券面值 -->
    <div class="dyb clearfix">
      <div class="dybleft">
        <!-- :style="{backgroundImage:'url(' + item.videopic + ')',backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundSize: 'contain'}" -->
        <div class="card" :style="{
          backgroundImage: 'url(' + cardMsg.logo_url + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '100% 100%'
        }">
          <!-- <p class="title">{{name}}</p> -->
          <div class="money">{{ cardMsg.card_name }}</div>
          <div class="time">
            有效期：{{ this.cardMsg.start_time | unixToDate('yyyy-MM-dd') }} 至
            {{ this.cardMsg.end_time | unixToDate('yyyy-MM-dd') }}
          </div>
        </div>
      </div>
      <!-- 卡券详情 -->
      <div class="righta">
        <!-- 代金券 -->
        <div class="dybright" v-if="this.cardMsg.card_type == 2 ? false : true">
          <!-- 名称 -->
          <div>
            <span class="add" style="width:93px;text-align:end;display:inline-block;">卡券名称：</span>
            <el-input v-model="cardMsg.card_name" maxlength="20" class="allwith"></el-input>
          </div>

          <!-- 时间 -->
          <div class="block1">
            <span class="add" style="width:93px;text-align:end;display:inline-block;">有效期：</span>
            <div class="block">
              <el-date-picker class="allwith" v-model="time" @change="daterangeDateChange" type="daterange"
                start-placeholder="开始日期" end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"></el-date-picker>
            </div>
          </div>
          <div class="block1">
            <span class="add" style="width:93px;text-align:end;display:inline-block;">面值：</span>
            <div class="block">
              <el-input style="width:160px;"
                @input="cardMsg.card_value = cardMsg.card_value.replace(/[^\d|^\.]/g, '').replace(/^0{1,}(?=\d)/g, '')"
                @blur="cardMsg.card_value = isPositiveDecimal(cardMsg.card_value)" v-model="cardMsg.card_value"
                :disabled="category === 'new' ? false : true">
                <span slot="append">元</span>
              </el-input>
            </div>
          </div>
          <!-- 充值卡有 所属商城 -->
          <div class="block1" v-if="cardMsg.card_type === 4">
            <span class="add" style="width:93px;text-align:end;display:inline-block;">所属商城：</span>
            <div class="block">
              <el-select size="medium" class="allwith" v-model="cardMsg.shop_id" @change="handleSelectShopName">
                <el-option v-for="item in shop_list" :key="item.shop_id" :label="item.shop_name"
                  :value="item.shop_id"></el-option>
              </el-select>
            </div>
          </div>
          <!-- logo -->
          <div class="logo">
            <span style="width:93px;text-align:end;margin-top: 8px;">图片：</span>
            <el-upload class="upload-demo" :action="`${MixinUploadApi}?scene=goods`" :on-preview="handlePreview"
              :on-success="uploadSuccessLogo" :limit="1" :on-exceed="handleExceed" :on-remove="handleRemove"
              :file-list.sync="fileList" list-type="picture">
              <div slot="tip" class="el-upload__tip">
                建议尺寸：850像素*350像素，大小不超过2M，只允许上传1张
              </div>
              <el-button size="small" type="primary">添加</el-button>
            </el-upload>
          </div>
          <!-- 卡券备注 -->
          <div>
            <span style="width:93px;text-align:end;display:inline-block;height:54px">卡券备注：</span>
            <el-input class="kjbz allwith" type="textarea" placeholder="请输入内容" v-model="cardMsg.remark" maxlength="80"
              show-word-limit></el-input>
          </div>
          <div v-if="cardMsg.card_type !== 3">
            <el-button v-if="$route.query.id" type="primary" size="small" style="margin-top: 22px;"
              @click="handleModifyCard">修改
            </el-button>
            <el-button v-else type="primary" size="small" style="margin-top: 22px;" @click="next">下一步
            </el-button>
          </div>
          <div v-if="cardMsg.card_type === 3">
            <!-- 代金券的时候 直接一步完成 -->
            <el-button v-if="$route.query.id" type="primary" size="small" style="margin-top: 22px;"
              @click="handleModifyCard">修改
            </el-button>
            <el-button v-else type="primary" size="small" style="margin-top: 22px;" @click="djjOk">完成
            </el-button>
          </div>
        </div>
        <div class="dybright" v-if="cardMsg.card_type == 2 ? true : false">
          <div>
            <span style="width:93px;text-align:end;display:inline-block;">优惠券标题：</span>
            <el-input v-model="cardMsg.card_name" class="allwith"></el-input>
          </div>
          <!-- 时间 -->
          <div class="block1">
            <span style="width:93px;text-align:end;display:inline-block;">有效期：</span>
            <div class="block">
              <el-date-picker class="allwith" v-model="time" @change="daterangeDateChange" type="daterange"
                start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
                :disabled="category == 'new' ? false : true"></el-date-picker>
            </div>
          </div>
          <!-- 卡券备注 -->
          <div>
            <span style="width:93px;text-align:end;display:inline-block;" class="text">使用须知：</span>
            <el-input class="kjbz allwith" type="textarea" placeholder="请输入内容" v-model="cardMsg.remark" maxlength="30"
              show-word-limit></el-input>
          </div>
          <!-- 跳转链接 -->
          <div style="margin-top:20px">
            <span style="width:93px;text-align:end;display:inline-block;">跳转链接：</span>
            <el-radio v-model="radio" :label="1">跳转到首页</el-radio>
            <el-radio v-model="radio" :label="2">自定义链接</el-radio>
            <p style="padding-left:93px;width:340px">
              您可设置用户领取优惠券后，提示跳转商城首页或自定义链接
            </p>
            <el-input v-if="radio === 2" v-model="cardMsg.url" style="margin-left:93px" class="allwith"
              placeholder="如：https://cfyg.com"></el-input>
          </div>
        </div>
        <div class="dybright" v-if="cardMsg.card_type == 2">
          <p>优惠券内容</p>
          <div style="margin-bottom:14px;padding-left:20px">
            <span>优惠券类型：</span>
            <el-radio :disabled="category == 'new' ? false : true" v-model="cardMsg.coupon_type" :label="1">折扣券
            </el-radio>
            <el-radio :disabled="category == 'new' ? false : true" v-model="cardMsg.coupon_type" :label="2">满减券
            </el-radio>
          </div>
          <div style="padding-left:30px" v-if="cardMsg.coupon_type == 1">
            <span>折扣价：</span>
            <el-input-number ref="discount" v-model="cardMsg.card_value" style="width:50%" :max="9.9" :min="0"
              :step="0.1" :precision="1" placeholder="0~9.9" @blur="discountMsg = ''"
              :disabled="category == 'new' ? false : true"></el-input-number>
            <span style="margin-left:20px;color:red;">{{ discountMsg }}</span>
            <!-- <el-input-number v-model="num1" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number> -->
          </div>
          <div style="padding-left:30px" v-else>
            <span>满减金额：满</span>
            <el-input style="width: 60px;" size="medium" :controls="false" v-model="cardMsg.coupon_threshold_price"
              :disabled="category == 'new' ? false : true"></el-input>
            <span style="margin: 0 4px;">元，减</span>
            <el-input style="width: 60px;" size="medium" :controls="false" v-model="cardMsg.card_value"
              :disabled="category == 'new' ? false : true"></el-input>
            <span style="margin: 0 4px;">元</span>
          </div>
        </div>
        <div class="dybright" v-if="cardMsg.card_type == 2 ? true : false">
          <p>适用范围</p>
          <el-radio v-model="cardMsg.goods_limit" :label="0">全场通用</el-radio>
          <el-radio v-model="cardMsg.goods_limit" :label="1">部分商品使用
          </el-radio>
          <div style="padding-left:30px" v-if="cardMsg.goods_limit == 1">
            <span>已选择商品，共{{ goods_sum }}件</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <el-button type="text" @click="handleChangeShop">去选择商品
            </el-button>
          </div>
          <div>
            <el-button v-if="$route.query.id" type="primary" size="small" style="margin-top: 22px;"
              @click="handleModifyCard">修改
            </el-button>
            <el-button v-else type="primary" style="margin-top: 22px;" size="small" @click="next1">保存
            </el-button>
          </div>
        </div>
      </div>
      <en-goods-picker-fx :show="dialogGoodsShow" :showChooseBtn="false" :default-data="chooseChangeList"
        @close="dialogGoodsShow = false" @chooseAll="allChoose" :fdList="fdList" />
    </div>
  </div>
</template>
<script>
import * as API_coupon from "@/api/coupon";
import * as API_MallManageMent from "@/api/mallManagement";
import * as API_Floor from "@/api/floor";
import { Foundation } from "@/../ui-utils";
// import { $oss } from "@/services/oss/oss"

export default {
  data () {
    return {
      name: "",
      textarea: "",
      time: [],
      radio: 1,
      cardMsg: {
        coupon_type: 1, //优惠券类型
        goods_limit: 0, //优惠券适用范围   0 全部商品   1 部分商品
        card_name: "", //优惠券标题
        start_time: 0, //优惠券开始时间
        end_time: 0, //优惠券标题结束时间
        card_type: 2, //   1：礼品卡 2：优惠券 3：代金券 4：充值卡
        coupon_threshold_price: 0, //优惠门槛价格
        card_value: 0, //优惠券面值
        limit_num: 0, //每人限领数量
        remark: "", //优惠券备注
        url: "", //优惠券跳转链接  自定义链接
        logo_url: "",
        state: 1,
        flag: 2,
        // 挑选的id
        goods_ids: [],
      },
      fileList: [
        // {name: "",url: ""}
      ],
      id: "",
      dialogGoodsShow: false,
      params: {
        //会员价格大值
        high_price: "",
        //会员价格小值
        low_price: "",
        //市场价格大值
        high_mktprice: "",
        //市场价格小值
        low_mktprice: "",
        //商品来源，0普通商品，1京东商品，2苏宁商品
        goods_source: "1",

        self_goods: "2",
        //利润率大值
        profit_max: "",
        //利润率小值
        profit_min: "",
        //商品名称
        keyword: "",
        //页码
        page_no: 1,
        //分页数
        page_size: 20,
        //排序
        sort: "",
        category_path: "",
      },
      chooseChangeList: [],
      tableData: {},
      // 列表总数据
      data_total: 0,
      goods_sum: 0,
      discountMsg: "",

      shop_list: [],
      fdList: [],
      category: "new",
    };
  },
  props: {
    yfather: Object,
  },
  watch: {
    $route: function () {
      if (this.$route.name === "couponEdit") {
        this.cardMsg.card_type = 2;
        this.name = "优惠券";
        console.log(this.cardMsg.card_type, "cardMsg.card_type");
      } else if (this.$route.name === "cashCouponEdit") {
        this.cardMsg.card_type = 3;
        this.name = "代金券";
        console.log(this.cardMsg.card_type, "cardMsg.card_type");
      } else {
        this.name = "充值卡";
        this.cardMsg.card_type = 4;
      }
    },
    radio: function () {
      if (this.radio === 1) this.cardMsg.url = "";
    },
    "cardMsg.coupon_type": {
      handler () {
        if (!this.$route.query.id) {
          this.cardMsg.coupon_threshold_price = "";
          this.cardMsg.card_value = "";
        }
      },
      deep: true
    }
  },

  created () {
    if (this.$route.query.id) {
      // this.cardDetail();
      this.getcardDetail();
      this.category = "edit";
    }
    console.log(this.$route.name, "this.$route.name");
    if (this.$route.name === "cashCouponEdit") {
      this.name = "代金券";
      this.cardMsg.card_type = 3;
    } else if (this.$route.name === "couponEdit") {
      this.name = "优惠券";
      this.cardMsg.card_type = 2;
    } else {
      this.name = "充值卡";
      this.cardMsg.card_type = 4;
      // 获取商城列表
      API_MallManageMent.getMalllManageMentList()
        .then((res) => {
          this.shop_list = JSON.parse(JSON.stringify(res));
        })
        .catch(() => { });
    }

    this.$nextTick(($) => {
      let that = this;
      if (this.cardMsg.card_type !== 2) {
        return;
      }
      let numDom = this.$refs?.discount?.$refs.input.$refs.input;
      numDom?.addEventListener("input", function () {
        if (
          this.value > 9.9 ||
          this.value < 0 ||
          !/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(
            this.value
          )
        ) {
          that.discountMsg = "请输入有效折扣";
        } else {
          that.discountMsg = "";
        }
      });
    });
  },
  methods: {
    isPositiveDecimal (card_value) {
      const regex = /^\d+(\.\d{1,2})?$/;
      if (regex.test(card_value)) {
        return card_value;
      } else {
        return '';
      }
    },
    getNavs () {
      API_Floor.getNav().then((response) => {
        response.forEach((res) => {
          res.self_goods = Foundation.getAllKey(res.url).self_goods;
        });
        this.fdList = response;
      });
    },
    handleSelectShopName () {
      // shop_list
      this.cardMsg.shop_name = this.shop_list.find(
        (o) => o.shop_id === this.cardMsg.shop_id
      ).shop_name;
    },
    djjOk () {
      if (!this.cardMsg.card_name) {
        this.$message.error("请输入卡券名称！");
      } else if (isNaN(parseInt(this.cardMsg.card_value))) {
        this.$message.error("卡券面值须是小数点两位以内的正数！");
      } else if (this.cardMsg.card_value === "0" || !this.cardMsg.card_value) {
        this.$message.error("请输入卡券面值！");
      } else if (parseFloat(this.cardMsg.card_value) <= 0) {
        this.$message.error("卡券面值必须大于0！");
      } else if (parseFloat(this.cardMsg.card_value) > 500000) {
        this.$message.error("卡券面值不可超过500000元！");
      } else if (!this.cardMsg.start_time || !this.cardMsg.end_time) {
        this.$message.error("请选择有效期！");
      } else {
        API_coupon.cardAdd(this.cardMsg)
          .then(() => {
            this.$router.push({ path: "/tools/promotion-manage/cash-coupon-list" });
          })
          .catch(() => { });
      }
    },
    // 商品选择器抛出的方法
    allChoose (list) {
      let ids = [];
      list.forEach((item) => {
        ids.push(item.goods_id);
      });
      this.cardMsg.goods_ids = ids;
      this.goods_sum = ids.length;
    },
    // 去选择商品按钮
    handleChangeShop () {
      this.getNavs();
      this.dialogGoodsShow = true;
    },
    getcardDetail () {
      API_coupon.getCardmsg({ card_id: this.$route.query.id }).then((res) => {
        this.cardMsg = res;
        console.log(this.cardMsg, 'this.cardMsg');
        this.$set(this.cardMsg, "card_name", res.card_name);
        this.$set(this.cardMsg, "logo_url", res.logo_url);
        this.time = [res.start_time * 1000, res.end_time * 1000];
        if (res.logo_url) {
          this.fileList = [
            {
              name: "",
              url: res.logo_url,
            },
          ];
        }
        if (res.url) this.radio = 2;
        if (res.goods_limit === 1) {
          API_coupon.getSelectedGoods({
            activity_id: this.$route.query.id,
          }).then((res) => {
            this.chooseChangeList = res;
            this.allChoose(res);
          });
        }
      });
    },

    handleModifyCard () {
      let obj = {};
      /*
        coupon_type: 1, //优惠券类型
        goods_limit: 0, //优惠券适用范围
        card_name: "", //优惠券标题
        start_time: 0, //优惠券开始时间
        end_time: 0, //优惠券标题结束时间
        card_type: 2, //   1：礼品卡 2：优惠券 3：代金券 4：充值卡
        coupon_threshold_price: 0, //优惠门槛价格
        card_value:"",//优惠券面值
        limit_num: 0, //每人限领数量
        remark:"",//优惠券备注
        url: ""//优惠券跳转链接  自定义链接
        logo_url: ""
      */
      obj.card_name = this.cardMsg.card_name;
      obj.card_type = this.cardMsg.card_type;
      obj.coupon_type = this.cardMsg.coupon_type;
      obj.goods_limit = this.cardMsg.goods_limit;
      obj.start_time = this.cardMsg.start_time;
      obj.end_time = this.cardMsg.end_time;
      obj.coupon_threshold_price = this.cardMsg.coupon_threshold_price;
      obj.card_value = this.cardMsg.card_value;
      obj.remark = this.cardMsg.remark;
      obj.url = this.cardMsg.url;
      obj.state = this.cardMsg.state;
      obj.goods_ids = this.cardMsg.goods_ids;
      obj.logo_url = this.cardMsg.logo_url;
      if (this.cardMsg.card_type === 4) {
        obj.shop_id = this.cardMsg.shop_id;
      }
      if (!this.cardMsg.card_name) {
        this.$message.error("请输入卡券名称！");
        return;
      } else if (!this.cardMsg.start_time || !this.cardMsg.end_time) {
        this.$message.error("请选择有效期！");
        return;
      } else if (!this.cardMsg.shop_id) {
        this.$message.error("请选择所属商城！");
        return;
      } else {
        API_coupon.editCardmsg(this.$route.query.id, obj).then((res) => {
          this.$message.success("修改成功");
          this.$router.go(-1);
        });
      }
    },
    // 图片上传个数超出后的回调
    handleExceed () {
      this.$message.warning(`最多可选一张图片作为背景图`);
    },
    //图片上传成功后的回调
    uploadSuccessLogo (response) {
      this.cardMsg.logo_url = response.url;
    },
    daterangeDateChange (val) {
      console.log(val, "valllllll");
      this.cardMsg.start_time = val ? parseInt(val[0] / 1000) : "";
      this.cardMsg.end_time = val ? parseInt(val[1] / 1000) : "";
    },
    next () {
      if (!this.cardMsg.card_name) {
        this.$message.error("请输入卡券名称！");
        return;
      } else if (!this.cardMsg.start_time || !this.cardMsg.end_time) {
        this.$message.error("请选择有效期！");
        return;
      } else if (!this.cardMsg.shop_id) {
        this.$message.error("请选择所属商城！");
        return;
      } else {
        this.$emit("fson", this.cardMsg);
      }
    },
    next1 () {
      this.$emit("fson", this.cardMsg);
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
      this.cardMsg.logo_url = "";
    },
    handlePreview (file) {
      console.log(file);
    },
    customUpload (options) {
      let onUploadProgress = (e) => {
        if (e.total > 0) {
          e.percent = (e.loaded / e.total) * 100
        }
        options.onProgress(e)
      }
      $oss.upload(options.file, { dirname: '', onUploadProgress }).then(res => {
        options.onSuccess(res)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.add:before {
  content: "*";
  color: #a91a1a;
  margin-right: 4px;
}

.goods-image {
  width: 50px;
  height: 50px;
}

.dyb /deep/ .el-dialog__body {
  padding: 20px 20px;
}

.conditions {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 20px;
}

.el-pagination {
  padding: 10px 0px;
  text-align: right;
}

.allwith {
  width: 240px;
}

.kjbz {
  /* margin-top: 20px; */
  display: inline-block;
}

.block1 .block {
  padding: 20px 0px;
  display: inline-block;
  width: 100px;
}

.zhi {
  margin-left: 20px;
}

.block1 .block .el-date-editor {
  width: 240px;
}

.block1 .block .el-date-editor .el-input__inner {
  text-align: center;
  padding-left: 15px;
  padding-right: 0px;
}

.dyb {
  box-sizing: border-box;
  /* margin-top: 50px; */
  padding-left: 200px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card {
  position: relative;
  overflow: hidden;
  width: 350px;
  height: 200px;
  background: rgba(71, 162, 236, 1);
  border-radius: 10px;
}

.card .title {
  margin: 0px;
  width: 36px;
  height: 12px;
  margin-left: 19px;
  margin-top: 25px;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}

.card .money {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* height: 31px; */
  font-size: 30px;
  font-family: DINCond-Black;
  font-weight: 900;
  color: rgba(255, 255, 255, 1);
  margin-left: 12px;
  margin-top: 16px;
}

.card .time {
  position: absolute;
  bottom: 10px;
  margin-top: 38px;
  margin-left: 12px;
  height: 9px;
  font-size: 9px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.righta {
  float: left;
}

.dybright {
  padding: 25px 40px;
  margin-left: 50px;
  /* border: 1px solid #ccc; */
  width: 560px;
  margin-bottom: 20px;
}

.logo {
  margin: 20px 0px;
  display: flex;
}

.logo .upload-demo {
  display: inline-block;
  width: 240px;
}

.text {
  display: inline-block;
  line-height: 54px;
}
</style>
