<template>
  <div>
    <!-- 流程图 -->
    <div class="lct">
      <h1 class="title">
        <span class="gun">丨</span> 生成兑换码
      </h1>
      <div class="mid clearfix">
        <div class="lie">
          <img src="../../../../../assets/one.png" alt />
          <p>设置面值和生成数量 生成兑换码 | 二维码</p>
        </div>
        <img class="image" src="../../../../../assets/rightjt.png" alt />
        <div class="lie">
          <img src="../../../../../assets/two.png" alt />
          <p>导出代金券兑换码 | 二 维码</p>
        </div>
        <img class="image" src="../../../../../assets/rightjt.png" alt />
        <div class="lie">
          <img src="../../../../../assets/three.png" alt />
          <p>将兑换码 | 二维码印刷 成卡 | 券</p>
        </div>
        <img class="image" src="../../../../../assets/rightjt.png" alt />
        <div class="lie">
          <img src="../../../../../assets/four.png" alt />
          <p>用户输入兑换码 | 扫码 直接领取代金券</p>
        </div>
      </div>
    </div>
    <!-- 系统生成，报表导入 -->
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="系统生成" name="first">
          <!-- 面值 -->
          <!-- <div class="mz" v-if="yfather.card_type==2?false:true">
            <span>面值：</span>
            <el-input v-model="cardMsg.card_value"></el-input>&nbsp;&nbsp;元
          </div> -->
          <!-- 生成数量 -->
          <div class="mz">
            <span>生成数量：</span>
            <el-input v-model="cardMsg.num"></el-input>&nbsp;&nbsp;张
          </div>
          <el-button type="primary" style="margin-top: 12px;" size="small" @click="next1">完成</el-button><!--  下一步  -->

        </el-tab-pane>
        <el-tab-pane label="报表导入" name="second">
          <!-- 模板 -->
          <div class="clearfix wenzi">
            <div class="left">
              第一步：请先下载模板并填写券码ID和兑换码。
              1.卡号为6-16位，兑换码为8-20位；
              <br />2.每次导入不超过5000条。
            </div>
            <div class="right">第二步：上传编辑完成的模板。</div>
            <el-button type="primary" size="mini" @click="downTemplate">下载模板</el-button>
            <div v-if="falg" class="modelstyle clearfix">
              <h4 class="texts">模板样式：</h4>
              <el-table class="moltable" :data="tableData" border style="width: 100%">
                <el-table-column prop="date" label="卡号"></el-table-column>
                <el-table-column prop="name" label="兑换码"></el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 面值 -->
          <div class="mz">
            <span>上传模板：</span>
            <el-input v-model="file_name"></el-input>&nbsp;&nbsp;
            <upload-excel-component defaultSize :on-success="excelSuccess" />
          </div>
          <!-- 生成数量 -->
          <!-- <div class="mz">
            <span>面值：</span>
            <el-input v-model="cardMsg.coupon_price"></el-input>&nbsp;&nbsp;元
          </div> -->
          <div>
            <el-button type="primary" style="margin-top: 12px;" size="small" @click="next">完成</el-button><!--  下一步  -->
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 按钮 -->
  </div>
</template>
<script>
import { handleDownload } from "@/utils";
import UploadExcelComponent from "@/components/UploadExcel";
export default {
  components: {
    UploadExcelComponent,
  },
  data() {
    return {
      falg: false,
      tableData: [
        {
          date: "1",
          name: "123",
        },
      ],
      templateKey: ["card_code", "card_key"],
      templateHedaer: ["card_code", "card_key"],
      falg: false,
      cardMsg: {
        coupon_price: "",
        card_value: "",
        flag: 3,
        create_num: "",
        cardsKeysDTO: {},
        num: "",
      },

      activeName: "first",

      fileList: [],
      file_name: "",
    };
  },
  props: {
    yfather: Object,
  },
  mounted() {
    console.log(this.yfather, "fatherfatherfatherfather");
  },
  methods: {
    excelSuccess({ results, filename }) {
      this.file_name = filename;
      let re_list = [];
      results.forEach((item) => {
        let obj = {};
        obj["card_code"] = item[Object.keys(item)[0]];
        obj["card_key"] = item[Object.keys(item)[1]];
        re_list.push(obj);
      });
      this.cardMsg.cardsKeysDTO.card_id = this.yfather.card_id;
      this.cardMsg.cardsKeysDTO.cards_key_dolist = re_list;

      this.cardMsg.create_num = this.cardMsg.cardsKeysDTO.cards_key_dolist.length;
      console.log(re_list, "上传的东西");
      console.log(this.cardMsg, "需要的东西");
    },
    downTemplate() {
      let modelData = [
        {
          card_id: "",
          prefix_code: "",
          num: "",
        },
      ];

      handleDownload(
        modelData,
        ["卡号", "兑换码"],
        this.templateKey,
        "导入模板"
      );
    },
    downloads() {
      this.flag = true;
    },
    next() {
      this.cardMsg.cardsKeysDTO.card_value = Number(this.cardMsg.coupon_price);
      console.log(this.cardMsg, "next需要给的东西");
      this.$emit("fson", this.cardMsg);
      if (this.yfather.card_type == 2) {
        this.$router.push({ path: "/tools/promotion-manage/coupon-list" });
      } else if (this.yfather.card_type == 3) {
        this.$router.push({ path: "/tools/promotion-manage/cash-coupon-list" });
      } else {
        this.$router.push({ path: "/application/czklist" });
      }
    },
    next1() {
      if (Number(this.cardMsg.num) > 5000) {
        this.$message.error("一次最多生成5000条");
        return;
      }
      this.cardMsg.cardsKeysDTO.card_value = Number(this.cardMsg.coupon_price);
      this.cardMsg.chang = true;
      console.log(this.cardMsg, "next1");
      if (this.yfather.card_id) this.cardMsg.card_id = this.yfather.card_id;
      this.$emit("fson", this.cardMsg);
      if (this.yfather.card_type == 2) {
        this.$router.push({ path: "/tools/promotion-manage/coupon-list" });
      } else if (this.yfather.card_type == 3) {
        this.$router.push({ path: "/tools/promotion-manage/cash-coupon-list" });
      } else {
        this.$router.push({ path: "/application/czklist" });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style  lang="scss" scoped>
.modelstyle .moltable {
  float: left;
  width: 176px !important;
}
.modelstyle .texts {
  float: left;
}

.modelstyle {
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background-color: #f2f2f2;
  width: 320px;
  height: 97px;
}
.wenzi {
  position: relative;
  color: #666666;
  margin-left: 150px;
  padding: 20px;
  height: 100px;
  background-color: #f2f2f2;
  line-height: 20px;
}
.wenzi .left {
  float: left;
  width: 300px;
}
.wenzi .right {
  margin-left: 50px;
  float: left;
}
.lct {
  padding: 10px 10px 38px 10px;
  background-color: #fff;
  text-align: center;
}

.lct .mid .lie {
  display: inline-block;
  margin: 0 auto;
  /* margin-left: 75px; */
  width: 72px;
}
.lct .mid .lie p {
  transform: scale(0.9) translate(-26px);
  width: 120px;
  font-size: 8px;
}
.lct .mid {
  width: 100%;
}
.mz {
  margin: 20px 150px;
}
.mz .upload-demo {
  display: inline-block;
}
.mz span {
  display: inline-block;
  text-align: end;
  width: 80px;
}
.mz .el-input {
  width: 50%;
}
.btnn span {
  text-align: center;
}
.image {
  transform: translateY(-20px);
  width: 75px;
  font-size: 18px;
}
.lct .title {
  text-align: start;
  font-size: 12px;
  margin: 10px 0 35px 0px;
}
.gun {
  color: #1a43a9;
  text-align: start;
  margin-left: -15px;
}
</style>
