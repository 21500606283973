<template>
  <div class="card-edit">
    <el-button
    style="position: absolute;right:30px"
          @click="
            () => {
              $router.go(-1)
            }
          "
          size="small"
          class="back_btn"
        >
          <!-- <img src="@/assets/back.png" alt="" /> -->
          返回
        </el-button>
    <el-steps class="steps" :active="active" finish-status="success" simple v-if="source !== 'coupon'">
      <el-step title="创建卡券"></el-step>
      <el-step title="设置发放规则"></el-step>
      <!-- <el-step title="完成"></el-step> -->
    </el-steps>
    <!-- card-edit-step-first 是一个公共组件 为了不产生样式冲突重新抽离新的组件 source === 'coupon'表示页面从优惠券跳转-->
    <template v-if="source === 'coupon'">
      <couponDetailInfo></couponDetailInfo>
    </template>
    <template v-else>
      <card-edit-step-first v-if="this.active === 1" @fson="fSonStep1"></card-edit-step-first>

      <card-edit-step-second v-if="this.active === 2" @fson="fSonStep2" :yfather="obj2"></card-edit-step-second>

      <card-edit-step-third v-if="this.active === 3" :yfather="cardMsgall"></card-edit-step-third>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_coupon from "@/api/coupon";
import cardEditStepFirst from "./components/card-edit/card-edit-step-first";
import couponDetailInfo from "./components/card-edit/card-edit-step-first-coupon"; // 添加/编辑优惠券
import cardEditStepSecond from "./components/card-edit/card-edit-step-second";
import cardEditStepThird from "./components/card-edit/card-edit-step-third";
export default {
  computed: {
    ...mapGetters(["shopInfo"]),
  },
  components: {
    cardEditStepFirst,
    cardEditStepSecond,
    cardEditStepThird,
    couponDetailInfo
  },
  data () {
    return {
      active: 1,
      obj1: {},
      obj2: {
        shop_type: "",
        card_type: 2,
      },
      cardMsgall: {
        shop_type: "",
      },
      card_type: "",
      cardmsg: {},
      type: "new",
      source: ''
    };
  },
  mounted () {
    this.source = this.$route?.query?.source ?? '' // 判断来源是否是优惠券
    this.$route.query.active &&
      (this.active = Number(this.$route.query.active));
    this.$route.query.card_id &&
      (this.obj2.card_id = Number(this.$route.query.card_id));
    let q = this.$route.query;
    if (Object.keys(q).length !== 0) {
      this.type = "edit";
    }

    // 记录是商家还是企业
    this.obj2.shop_type = this.shopInfo.shop_type;
    this.cardMsgall.shop_type = this.shopInfo.shop_type;
  },

  methods: {
    fSonStep1 (value) {
      this.active = value.flag;
      this.obj2 = value;
      let params = {
        ...value,
      };
      API_coupon.cardAdd(params)
        .then((res) => {
          this.obj2.id = res.id;
        })
        .catch(() => {
          this.active = value.flag - 1;
        });
    },
    fSonStep2 (value) {
      this.active = value.flag;
      this.obj1 = value;
      let newobj = {};

      Object.assign(newobj, this.obj1, this.obj2);
      this.cardMsgall = newobj;
      if (value.card_id) {
        this.cardMsgall.card_id = value.card_id;
      } else {
        this.cardMsgall.card_id = this.cardMsgall.id;
      }

      if (this.$route.query.id) {
        // 修改按钮  传过来一个id值
        this.cardMsgall.id = Number(this.$route.query.id);

        API_coupon.modifyCoupons(this.cardMsgall.id, this.cardMsgall)
          .then((res) => {
            console.log(res, "修改成功");
          })
          .catch(() => {
            this.active = value.flag - 1;
          });
      } else {
        if (this.cardMsgall.chang) {
          //  自动生成卡密
          this.cardMsgall.card_value = value.card_value;
          API_coupon.createCardKeys(this.cardMsgall)
            .then((res) => {
              console.log(res, "自动添加成功");
            })
            .catch(() => {
              this.active = value.flag - 1;
            });
        } else {
          //  手动生成卡密
          API_coupon.importCardKeys(value.cardsKeysDTO)
            .then((res) => {
              console.log(res, "生成卡密成功");
            })
            .catch(() => {
              this.active = value.flag - 1;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-edit {
  position: relative;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.steps {
  background-color: #fff;
  padding-top: 20px;
}

.btn3 {
  padding: 30px 0;
  text-align: center;
}
</style>

